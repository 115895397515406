export const ApiEndPoints = {
  LOGIN_API_CALL: "auth/signin",
  PROFESSION_RESOURCE_ROUTE: "/professions",
  GET_SPECIALITIES_FOR_SELECT: "/specialities/get_specialities_for_select",
  GET_FACILITIES_FOR_SELECT: "/common/get-facilities-for-select",
  GET_LANGUAGES_FOR_SELECT: "/common/get-languages-for-select",
  GET_SERVICES_FOR_SELECT: "/common/get-services-for-select",
  GET_ZONES_FOR_SELECT: "/common/zones-for-select",
  GET_CITIES_FOR_SELECT: "/common/cities-for-select",
  GET_ESTABLISHMENT_FOR_SELECT: "/establishments/get_list_for_select",
  GET_PROFESSIONS_FOR_SELECT: "/professions/get-professions-for-select",
  GET_ESTABLISHMENT_TYPES_FOR_SELECT:
    "/establishment-types/get_establishment_types_for_select",
  GET_ESTABLISHMENT_SUB_TYPES_FOR_SELECT:
    "/establishment-sub-types/get_establishment_types_for_select",
  CREATE_ESTABLISHMENT_HOURS: "/establishments/manage-working-hours",
  GET_PROFESSION_TYPES_FOR_SELECT:
    "/profession-types/get-profession-types-for-select",
  NATIONALITIES_RESOURCE_ROUTE: "/nationalities",
  INSURANCES_RESOURCE_ROUTE: "/insurances",
  ZONES_RESOURCE_ROUTE: "/zones",
  CITIES_RESOURCE_ROUTE: "/cities",
  ESTABLISHMENT_RESOURCE_ROUTE: "/establishments",
  ESTABLISHMENT_BULK_UPLOAD_ROUTE: "/establishments/bulk-upload",

  SPECIALITIES_RESOURCE_ROUTE: "/specialities",
  DEPARTMENT_RESOURCE_ROUTE: "/departments",
  CREATE_DEPARTMENT_HOURS: "/departments/manage-working-hours",
  DEPARTMENT_HOURS_RESOURCE_ROUTE: "/department-hours",
  DEPARTMENT_HOLIDAYS_RESOURCE_ROUTE: "/department-holidays",
  ESTABLISHMENT_HOURS_RESOURCE_ROUTE: "/establishment-hours",
  ESTABLISHMENT_HOLIDAYS_RESOURCE_ROUTE: "/establishment-holidays",

  ESTABLISHMENT_TYPES: "/establishment-types",
  ESTABLISHMENT_SUB_TYPES: "/establishment-sub-types",
  FACILITIES: "/facilities",
  PROFESSION_TYPES: "/profession-types",
  BANNERS: "/banners",
  SERVICES: "/services",
  LANGUAGES: "/languages",
};
