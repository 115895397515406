import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  insuranceList: [],
  insuranceEnquiryList: [],
  insuranceDetail: null,
};
const insuranceSlice = createSlice({
  name: "insurance",
  initialState: initialState,
  reducers: {
    insuranceListSuccess: (state, action) => {
      state.insuranceList = action.payload;
    },
    insuranceListFailed: (state, action) => {
      state.insuranceList = [];
    },
    insuranceEnquiryListSuccess: (state, action) => {
      state.insuranceEnquiryList = action.payload;
    },
    insuranceEnquiryListFailed: (state, action) => {
      state.insuranceEnquiryList = [];
    },
    insuranceDetailSuccess: (state, action) => {
      state.insuranceDetail = action.payload;
    },
    insuranceDetailFailed: (state, action) => {
      state.insuranceDetail = [];
    },
  },
});

export const {
  insuranceListSuccess,
  insuranceListFailed,
  insuranceEnquiryListSuccess,
  insuranceEnquiryListFailed,
  insuranceDetailSuccess,
  insuranceDetailFailed,
} = insuranceSlice.actions;
export default insuranceSlice.reducer;
